import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { SizeVariant } from 'enums'
import Button from 'components/buttons/Button'
import InformationModal from 'components/modals/InformationModal'
import { IModal } from 'components/modals/types'

const FillUserIndustriesNotificationModal = ({
  isOpen,
  isLoading,
  onClose
}: IModal & { isLoading?: boolean }) => {
  const { t } = useTranslation(['crm', 'actions'])
  const router = useRouter()

  return (
    <InformationModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={t('candidate.modals.fillIndustries.title', {
        ns: 'crm'
      })}
      titleVariant={'h2'}
      subtitle={t('candidate.modals.fillIndustries.content', {
        ns: 'crm'
      })}
    >
      <Button
        isLoading={isLoading}
        size={SizeVariant.Small}
        onClick={() => {
          router.push('/user/my-cv')
          onClose?.()
        }}
      >
        {t('update', {
          ns: 'actions'
        })}
      </Button>
    </InformationModal>
  )
}

export default FillUserIndustriesNotificationModal
